import React from 'react'
import tw, { styled } from 'twin.macro'
import { useIntl } from 'react-intl'
//
import Icons from '~storybook/icons'

const PagerButton = styled.button`
  ${tw`no-underline px-5 py-3 text-c-pagination-linkColour rounded-sm`}

  &:hover,
  &:focus,
  &:active,
  &[aria-current='true'] {
    ${tw`bg-c-pagination-activeState text-c-pagination-hoverTextColour`}
  }
`

function range(startAt = 0, size) {
  return [...Array(size).keys()].map((i) => i + startAt)
}

const getPaginationOptions = ({
  listLength = 4,
  currentPage = 0, // page index starts at 0
  totalPages
}) => {
  const offset = Math.ceil(listLength / 2)

  let start = currentPage - offset
  let end = currentPage + offset

  if (totalPages <= listLength) {
    start = 0
    end = totalPages
  } else if (currentPage <= offset) {
    start = 0
    end = listLength
  } else if (currentPage + offset >= totalPages) {
    start = totalPages - listLength
    end = totalPages
  }

  if (end >= totalPages && listLength < totalPages) {
    end = totalPages - 1
  }

  return range(start, end)?.map((value) => value + 1)
}

const Pagination = ({ total = 20, page = 2, onPageChange = 1, limit = 5 }) => {
  if (!total || total <= limit) {
    return null
  }

  const intl = useIntl()

  const totalPages = Math.ceil(total / limit)
  const allPages = getPaginationOptions({ listLength: 4, currentPage: page - 1, totalPages })
  const pages = allPages.length > 4 ? allPages.slice(1, 5) : [...allPages]

  return (
    <div className="w-full" role="navigation" aria-label={intl.formatMessage({ id: 'pagination.navLabel' })}>
      <ul className="m-8 p-0 flex list-none items-center justify-center">
        {page > 1 ? (
          <li className="p-0 my-1">
            <button
              type="button"
              aria-label={intl.formatMessage({ id: 'pagination.prevLabel' })}
              onClick={() => {
                onPageChange(page - 1)
              }}
            >
              <Icons.ArrowLeft />
            </button>
          </li>
        ) : null}

        {pages.map((key) => (
          <li key={key} className="p-0 mx-2">
            <PagerButton
              type="button"
              css={[
                tw`border border-c-pagination-border`,
                page === key && tw`bg-c-pagination-activeState text-c-pagination-hoverTextColour`
              ]}
              {...(page === key
                ? { 'aria-current': 'true' }
                : { 'aria-label': intl.formatMessage({ id: 'pagination.linkLabel' }, { page: key }) })}
              onClick={() => {
                onPageChange(key)
              }}
            >
              {key}
            </PagerButton>
          </li>
        ))}

        {page < allPages.length ? (
          <li className="p-0 my-1">
            <button
              type="button"
              aria-label={intl.formatMessage({ id: 'pagination.nextLabel' })}
              onClick={() => {
                onPageChange(page + 1)
              }}
            >
              <Icons.ArrowRight />
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  )
}

export default Pagination
